import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyDccMrBQVJbuMM_d11V1Xhb1-FwE2eynW8",
  authDomain: "multibhashi-tech.firebaseapp.com",
  databaseURL: "https://multibhashi-tech.firebaseio.com",
  projectId: "multibhashi-tech",
  storageBucket: "multibhashi-tech.appspot.com",
  messagingSenderId: "543365014369",
  appId: "1:543365014369:web:8c1ad8727e7c914ab35b20",
  measurementId: "G-MW0LMZMN11",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const messaging = getMessaging(app);
