import {
    View,
    Text,
    TouchableOpacity,
    StyleSheet,
    Image,
    Pressable,
    ScrollView,
    FlatList,
    TextInput,
    Platform,
    Animated
} from "react-native";
import React, { useState, useEffect, useRef } from "react";
import tailwind from "twrnc";
import { height, isWeb, width } from "../../../../constants/constants";

import { useNavigation } from "@react-navigation/native";
import { AntDesign, Ionicons } from "@expo/vector-icons";

import { TEACHERS } from "../../../../api/instance";
import Layout from "../../layout";
import PageHeader from "../../headers/page.header";
import useQuiz from "./common/context";
import moment from "moment";
import ReactPlayer from "react-player";
import { RadioButton } from "react-native-paper";
import { Video, ResizeMode, Audio } from "expo-av";
import Alert from "../../../../utils/alert";

let wd = width <= 500 ? width : 499.99;
const headerHeight = isWeb ? 60 : 100;
const buttonHeight = 400;
const contentHeight = height - headerHeight - buttonHeight;

import * as FileSystem from 'expo-file-system';

export default function Quiz(props) {
    const {
        quiz,
        questions,
        current_question_index,
        question_start_timestamp,
        setQuestionStartTimestamp,
        checked,
        setChecked,
        setTotalCorrectAnswer,
        setTotalQuestionAttempt,
        setAnswerResult,
        setSelectedAnswer,
        setQuestion,
        setQuestionEndTimestamp,
        setTotalIncorrectAnswer,
        wq_text_answer,
        setWqTextAnswer,
        text_eval_ans,
        setTextEvalAns,
        question_time_duration,
        setQuestionTimeDuration,
        setQuestionTimeOut,
        total_timeout,
        setTotalTimeout
    } = useQuiz();

    const { navigate } = useNavigation();

    const [currentAudio, setCurrentAudio] = useState(null);
    let [playing, setPlaying] = useState(true);
    const [sound, setSound] = useState(null);
    const [duration, setDuration] = useState(0);
    const [sound1, setSound1] = useState(null);
    const video = React.useRef(null);

    let [seq_selected_answers, setSeqSelectedAnswers] = useState([]);
    let [seq_options, setSeqOptions] = useState([]);
    let [mtf_selected_answers, setMtfSelectedAnswers] = useState([]);
    let [mtf_options, setMtfOptions] = useState([]);

    const [isRecording, setIsRecording] = useState(false);
    const [audioURL, setAudioURL] = useState('');
    const [isPaused, setIsPaused] = useState(false);
    const [timer, setTimer] = useState(0);
    const [formattedTime, setFormattedTime] = useState('00:00:00');
    const audioChunksRef = useRef([]);
    const mediaRecorderRef = useRef(null);
    const timerIntervalRef = useRef(null);
    const [audioBlob, setAudioBlob] = useState(null);
    const [is_uploading_audio, setIsUploadingAudio] = useState(false);

    const [timeLeft, setTimeLeft] = useState(question_time_duration);
    const progress = useRef(new Animated.Value(0)).current;

    const barWidth = progress.interpolate({
        inputRange: [0, 1],
        outputRange: ["0%", "100%"],
    });

    const intervalRef = useRef(null);

    const [next_disabled, setNextDisabled] = useState(false);

    const wqTextAnswerRef = useRef(wq_text_answer);
    const audioBlobRef = useRef(audioBlob);

    const [is_lst_audio_played, setIsLstAudioPlayed] = useState(false);


    useEffect(() => {
        wqTextAnswerRef.current = wq_text_answer;
    }, [wq_text_answer]);

    useEffect(() => {
        audioBlobRef.current = audioBlob;
    }, [audioBlob]);

    useEffect(() => {
        wqTextAnswerRef.current = "";
        audioBlobRef.current = ""
        setIsUploadingAudio(false)
        setWqTextAnswer("");
        setQuestionStartTimestamp(moment().format('x'))
        setTimeLeft(question_time_duration)

        progress.setValue(0);
        if (questions[current_question_index]?.type_code != "LST") {
            Animated.timing(progress, {
                toValue: 1,
                duration: question_time_duration * 1000,
                useNativeDriver: false,
            }).start();

            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }

            intervalRef.current = setInterval(() => {
                setTimeLeft((prev) => {
                    setNextDisabled(false)

                    if (prev === 1) {
                        setNextDisabled(true)
                        clearInterval(intervalRef.current);
                        setIsUploadingAudio(true)
                        questionTimeOut(moment().format('x'));
                    }
                    return prev - 1;
                });
            }, 1000);
        }

        return () => {
            clearInterval(intervalRef.current); // Ensure the interval is cleared
            if (sound) {
                sound.unloadAsync(); // Cleanup sound
            }
        };


    }, [current_question_index]);


    useEffect(() => {

        if (questions[current_question_index]?.type_code == "MCQ") {

            if (questions[current_question_index].contents[0]?.audio) {
                if (Platform.OS != "web") {
                    let duration_millis = null
                    const loadSound = async () => {
                        if (sound) {
                            await sound.unloadAsync();
                        }

                        const { sound: newSound } = await Audio.Sound.createAsync(
                            { uri: questions[current_question_index].contents[0]?.audio },
                            { shouldPlay: true }
                        );
                        setSound(newSound);
                    };

                    setCurrentAudio(questions[current_question_index].contents[0]?.audio)
                    loadSound();
                }

            }
        }

        if (questions[current_question_index].type_code == "SEQ") {
            setSeqSelectedAnswers([])
            let seq_arr = [];
            seq_arr = [...questions[current_question_index].options];
            setSeqOptions(seq_arr);
        }

        if (questions[current_question_index].type_code == "MTF") {
            setMtfSelectedAnswers([])
            let mtf_arr = [];
            mtf_arr = [...questions[current_question_index].options];
            setMtfOptions(mtf_arr);
        }

        setAudioURL("")
        setAudioBlob(null)
        setTimer(0)
        setFormattedTime('00:00:00')

        if (questions[current_question_index].contents[0].audio) {
            setCurrentAudio(questions[current_question_index].contents[0].audio)
        } else {
            setCurrentAudio("")
        }

    }, [current_question_index]);

    useEffect(() => {
        // Timer logic when recording starts
        if (isRecording && !isPaused) {
            timerIntervalRef.current = setInterval(() => {
                setTimer((prev) => prev + 1);
            }, 1000);
        } else if (isPaused || !isRecording) {
            clearInterval(timerIntervalRef.current);
        }
        setIsUploadingAudio(false)

        // Cleanup timer on component unmount
        return () => clearInterval(timerIntervalRef.current);
    }, [isRecording, isPaused]);

    useEffect(() => {
        // Format time in HH:MM:SS format
        const hours = String(Math.floor(timer / 3600)).padStart(2, '0');
        const minutes = String(Math.floor((timer % 3600) / 60)).padStart(2, '0');
        const seconds = String(timer % 60).padStart(2, '0');
        setFormattedTime(`${hours}:${minutes}:${seconds}`);
        if (minutes == 1) {
            stopRecording()
        }
    }, [timer]);

    let checkAnswer = async () => {
        setQuestionTimeOut("no")
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        setPlaying(false)
        setCurrentAudio("")
        let question_end_time = moment().format('x')
        if (
            questions[current_question_index].type_code == "MCQ" ||
            questions[current_question_index].type_code == "TF"
        ) {
            let answer = "incorrect";
            if (
                questions[current_question_index].contents[0].correct_options[0].option ==
                checked._id
            ) {
                answer = "correct";
                setTotalCorrectAnswer((prev) => prev + 1);
                setTotalQuestionAttempt((prev) => prev + 1);
            }
            else {
                answer = "incorrect";
                setTotalIncorrectAnswer((prev) => prev + 1);
                setTotalQuestionAttempt((prev) => prev + 1);
            }

            setAnswerResult(answer);
            setSelectedAnswer(checked);
            setQuestion(questions[current_question_index]);
            setQuestionEndTimestamp(question_end_time)

            let obj = {
                back_route: "Quizzes",
            };

            navigate("QuizFeedback", obj);
        }

        if (questions[current_question_index].type_code == "SEQ") {
            let answer = "incorrect";

            let correct_options =
                questions[current_question_index].contents[0].correct_options;
            let ans_options = [];

            let c_opns = []
            if (correct_options.length > 0) {
                for (let index = 0; index < correct_options.length; index++) {
                    let item = correct_options[index];
                    c_opns.push(item.option);
                }
            }

            for (let index = 0; index < seq_selected_answers.length; index++) {
                let item = seq_selected_answers[index];
                ans_options.push(item._id);
            }

            let options_str = c_opns.toString();
            let ans_str = ans_options.toString();

            if (options_str == ans_str) {
                answer = "correct";
                setTotalCorrectAnswer((prev) => prev + 1);
                setTotalQuestionAttempt((prev) => prev + 1);
            }
            else {
                answer = "incorrect";
                setTotalIncorrectAnswer((prev) => prev + 1);
                setTotalQuestionAttempt((prev) => prev + 1);
            }

            setAnswerResult(answer);
            setSelectedAnswer(seq_selected_answers);
            setQuestion(questions[current_question_index]);
            setQuestionEndTimestamp(question_end_time)

            // setUrlIndex(0)
            // setUrlItems([])

            let obj = {
                back_route: "Quizzes",
            };

            navigate("QuizFeedback", obj);
        }

        if (questions[current_question_index].type_code == "MTF") {
            let ans = mtf_selected_answers.map(item => {
                return item._id
            })

            const isAscending = !ans.some((v, i) => v > ans[i + 1]);
            let answer = "incorrect";

            if (isAscending) {
                answer = "correct";
                setTotalCorrectAnswer((prev) => prev + 1);
                setTotalQuestionAttempt((prev) => prev + 1);
            }
            else {
                answer = "incorrect";
                setTotalIncorrectAnswer((prev) => prev + 1);
                setTotalQuestionAttempt((prev) => prev + 1);
            }

            setAnswerResult(answer);
            setSelectedAnswer(mtf_selected_answers);
            setQuestion(questions[current_question_index]);
            setQuestionEndTimestamp(question_end_time)

            // setUrlIndex(0)
            // setUrlItems([])

            let obj = {
                back_route: "Quizzes",
            };

            navigate("QuizFeedback", obj);
        }

        if (
            questions[current_question_index].type_code == "RC"
        ) {
            let answer = "incorrect";
            if (
                questions[current_question_index].contents[0].correct_options[0].option ==
                checked._id
            ) {
                answer = "correct";
                setTotalCorrectAnswer((prev) => prev + 1);
                setTotalQuestionAttempt((prev) => prev + 1);
            }
            else {
                answer = "incorrect";
                setTotalIncorrectAnswer((prev) => prev + 1);
                setTotalQuestionAttempt((prev) => prev + 1);
            }

            setAnswerResult(answer);
            setSelectedAnswer(checked);
            setQuestion(questions[current_question_index]);
            setQuestionEndTimestamp(question_end_time)

            let obj = {
                back_route: "Quizzes",
            };

            navigate("QuizFeedback", obj);
        }

        if (
            questions[current_question_index].type_code == "LST"
        ) {
            let answer = "incorrect";
            if (
                questions[current_question_index].contents[0].correct_options[0].option ==
                checked._id
            ) {
                answer = "correct";
                setTotalCorrectAnswer((prev) => prev + 1);
                setTotalQuestionAttempt((prev) => prev + 1);
            }
            else {
                answer = "incorrect";
                setTotalIncorrectAnswer((prev) => prev + 1);
                setTotalQuestionAttempt((prev) => prev + 1);
            }

            setAnswerResult(answer);
            setSelectedAnswer(checked);
            setQuestion(questions[current_question_index]);
            setQuestionEndTimestamp(question_end_time)

            let obj = {
                back_route: "Quizzes",
            };

            navigate("QuizFeedback", obj);
        }
    };

    let handleSEQAns = async (option) => {

        let res = seq_selected_answers.some((o) => o._id == option._id);
        if (!res) {
            let arr = seq_selected_answers;
            arr.push(option);
            setSeqSelectedAnswers([...arr]);

            let arr2 = seq_options;
            arr2.splice(
                arr2.findIndex((a) => a._id == option._id),
                1
            );
            setSeqOptions([...arr2]);
        }
    };

    let handleSEQAnsRemoved = (option) => {
        let res = seq_options.some((o) => o._id == option._id);
        if (!res) {
            let arr = seq_options;
            arr.push(option);
            setSeqOptions([...arr]);

            let arr2 = seq_selected_answers;
            arr2.splice(
                arr2.findIndex((a) => a._id == option._id),
                1
            );
            setSeqSelectedAnswers([...arr2]);
        }
    };

    let handleMtfAns = async (option) => {
        let res = mtf_selected_answers.some((o) => o._id == option._id);
        if (!res) {
            let arr = mtf_selected_answers;
            arr.push(option);
            setMtfSelectedAnswers([...arr]);

            let arr2 = mtf_options;
            arr2.splice(
                arr2.findIndex((a) => a._id == option._id),
                1
            );
            setMtfOptions([...arr2]);
        }
    };

    let handleMtfAnsRemoved = (option) => {
        if (option) {
            let res = mtf_options.some((o) => o._id == option._id);
            if (!res) {
                let arr = mtf_options;
                arr.push(option);
                setMtfOptions([...arr]);

                let arr2 = mtf_selected_answers;
                arr2.splice(
                    arr2.findIndex((a) => a._id == option._id),
                    1
                );
                setMtfSelectedAnswers([...arr2]);
            }
        }
    };

    const startRecording = async () => {
        try {
            setAudioBlob(null)
            audioChunksRef.current = []
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream);

            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };

            mediaRecorderRef.current.start();
            setIsRecording(true);
            setIsPaused(false);
        } catch (error) {
            console.error('Error accessing microphone', error);
        }
    };

    const pauseRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.pause();
            setIsPaused(true);
        }
    };

    const resumeRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.resume();
            setIsPaused(false);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
            setIsPaused(false);
            clearInterval(timerIntervalRef.current);

            mediaRecorderRef.current.onstop = () => {
                const audio_blob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
                const audioUrl = URL.createObjectURL(audio_blob);
                setAudioURL(audioUrl)
                // console.log('Recording URL:', audioUrl);
                setAudioBlob(audio_blob)
            };
        }
    };

    const deleteRecording = () => {
        setAudioURL("")
        setAudioBlob(null)
        setTimer(0)
        setFormattedTime('00:00:00')
    }

    const uploadAudio = async () => {
        setQuestionEndTimestamp(moment().format('x'))
        setQuestionTimeOut("no")
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        const formData = new FormData();
        formData.append('voice', audioBlob, 'recording-test.webm');

        try {
            setIsUploadingAudio(true)
            setWqTextAnswer("")

            const { data } = await TEACHERS.post(
                `/voiceevaluation`, formData, { headers: { "Content-Type": "multipart/form-data" } }
            );

            if (data && data.data && data.data != "") {
                setTextEvalAns("")
                setWqTextAnswer(data.data)
                let payload = {
                    "question": questions[current_question_index].contents[0].text,
                    "transcription": data.data
                }
                const { data: res } = await TEACHERS.post(
                    `/text/transcription`, payload
                );

                setTextEvalAns(res.data)

                let answer = "incorrect";

                let result = res.data
                setQuestion(questions[current_question_index]);
                if (result.relevance_score >= 5) {
                    answer = "correct";
                    setTotalCorrectAnswer((prev) => prev + 1);
                    setTotalQuestionAttempt((prev) => prev + 1);
                } else {
                    answer = "incorrect";
                    setTotalIncorrectAnswer((prev) => prev + 1);
                    setTotalQuestionAttempt((prev) => prev + 1);
                }
                setAnswerResult(answer);

                let obj = {
                    back_route: "Quizzes",
                };

                navigate("QuizFeedback", obj);
                setIsUploadingAudio(false)
            } else {
                Alert.alert("Something went Wrong.Please try again");
                setIsUploadingAudio(false)
            }
        } catch (error) {
            console.error('Error uploading audio:', error);
            setIsUploadingAudio(false)
        }
    };

    let checkWQAns = async () => {
        setQuestionEndTimestamp(moment().format('x'))
        if (
            questions[current_question_index].type_code == "WQ"
        ) {
            setQuestionTimeOut("no")
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
            setIsUploadingAudio(true)
            setTextEvalAns("")
            let payload = {
                "question": questions[current_question_index].contents[0].text,
                "answer": wq_text_answer
            }
            const { data } = await TEACHERS.post(
                `/textevaluation`, payload
            );

            if (data && data.data && data.data != "") {
                let result = data.data
                let answer = "incorrect";
                if (result.relevance_score >= 5) {
                    answer = "correct";
                    setTotalCorrectAnswer((prev) => prev + 1);
                    setTotalQuestionAttempt((prev) => prev + 1);
                } else {
                    answer = "incorrect";
                    setTotalIncorrectAnswer((prev) => prev + 1);
                    setTotalQuestionAttempt((prev) => prev + 1);
                }

                setAnswerResult(answer);
                setTextEvalAns(result)
                setQuestion(questions[current_question_index]);


                let obj = {
                    back_route: "Quizzes",
                };

                navigate("QuizFeedback", obj);
                setIsUploadingAudio(false)

            } else {
                Alert.alert("Something went Wrong.Please try again");
                setIsUploadingAudio(false)
            }
        }
    };

    let questionTimeOut = async (question_end_time) => {
        if (
            questions[current_question_index].type_code == "SQ"
        ) {
            if (mediaRecorderRef && mediaRecorderRef.current) {

                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }

                if (mediaRecorderRef.current.state == "inactive") {
                    questionSQTimeOut(audioBlobRef.current, question_end_time)
                }
                if (mediaRecorderRef.current.state == "recording") {
                    mediaRecorderRef.current.stop();
                    setIsRecording(false);
                    setIsPaused(false);

                    mediaRecorderRef.current.onstop = async () => {
                        const audio_blob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
                        const audioUrl = URL.createObjectURL(audio_blob);
                        setAudioURL(audioUrl)
                        setAudioBlob(audio_blob)
                        questionSQTimeOut(audio_blob, question_end_time)
                    }
                }
            } else {
                setNextDisabled(false)
                setQuestionTimeOut("yes")
                setTotalIncorrectAnswer((prev) => prev + 1);
                setTotalQuestionAttempt((prev) => prev + 1);

                setQuestion(questions[current_question_index]);
                setQuestionEndTimestamp(question_end_time)
                setTotalTimeout((prev) => prev + 1);

                setChecked(null)
                setQuestion(null)
                setAnswerResult("")
                setSelectedAnswer(null)
                setTextEvalAns(null)
                setWqTextAnswer("")

                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }

                let obj = {
                    back_route: "Quizzes",
                };

                navigate("QuizFeedback", obj);
            }
        } else if (
            questions[current_question_index].type_code == "WQ"
        ) {
            if (wqTextAnswerRef.current) {
                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }
                setIsUploadingAudio(true)
                setTextEvalAns("")
                let payload = {
                    "question": questions[current_question_index].contents[0].text,
                    "answer": wqTextAnswerRef.current
                }
                const { data } = await TEACHERS.post(
                    `/textevaluation`, payload
                );

                if (data && data.data && data.data != "") {
                    let result = data.data
                    let answer = "incorrect";
                    if (result.relevance_score >= 5) {
                        answer = "correct";
                        setTotalCorrectAnswer((prev) => prev + 1);
                        setTotalQuestionAttempt((prev) => prev + 1);
                    } else {
                        answer = "incorrect";
                        setTotalIncorrectAnswer((prev) => prev + 1);
                        setTotalQuestionAttempt((prev) => prev + 1);
                    }

                    setAnswerResult(answer);
                    setTextEvalAns(result)
                    setQuestion(questions[current_question_index]);

                    setQuestionEndTimestamp(question_end_time)
                    setQuestionTimeOut("yes")
                    setTotalTimeout((prev) => prev + 1);

                    let obj = {
                        back_route: "Quizzes",
                    };

                    navigate("QuizFeedback", obj);
                    setIsUploadingAudio(false)

                } else {
                    Alert.alert("Something went Wrong.Please try again");
                    setIsUploadingAudio(false)
                }

            } else {
                setNextDisabled(false)
                setQuestionTimeOut("yes")
                setTotalIncorrectAnswer((prev) => prev + 1);
                setTotalQuestionAttempt((prev) => prev + 1);

                setQuestion(questions[current_question_index]);
                setQuestionEndTimestamp(question_end_time)
                setTotalTimeout((prev) => prev + 1);

                setChecked(null)
                setQuestion(null)
                setAnswerResult("")
                setSelectedAnswer(null)
                setTextEvalAns(null)
                setWqTextAnswer("")

                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }

                let obj = {
                    back_route: "Quizzes",
                };

                navigate("QuizFeedback", obj);
            }
        } else {
            setQuestionTimeOut("yes")
            setTotalIncorrectAnswer((prev) => prev + 1);
            setTotalQuestionAttempt((prev) => prev + 1);

            setQuestion(questions[current_question_index]);
            setQuestionEndTimestamp(question_end_time)
            setTotalTimeout((prev) => prev + 1);

            setChecked(null)
            setQuestion(null)
            setAnswerResult("")
            setSelectedAnswer(null)
            setTextEvalAns(null)
            setWqTextAnswer("")

            setIsUploadingAudio(false)
            setCurrentAudio("")

            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }

            let obj = {
                back_route: "Quizzes",
            };

            navigate("QuizFeedback", obj);
        }
    };

    let questionSQTimeOut = async (audio_blb, question_end_time) => {
        console.log("questionSQTimeOut")
        const formData = new FormData();
        formData.append('voice', audio_blb, 'recording-test.webm');
        try {
            console.log("audio_blb : ", audio_blb)
            setWqTextAnswer("")
            const { data } = await TEACHERS.post(
                `/voiceevaluation`, formData, { headers: { "Content-Type": "multipart/form-data" } }
            );

            if (data && data.data && data.data != "") {
                setTextEvalAns("")
                setWqTextAnswer(data.data)
                let payload = {
                    "question": questions[current_question_index].contents[0].text,
                    "transcription": data.data
                }
                const { data: res } = await TEACHERS.post(
                    `/text/transcription`, payload
                );

                setTextEvalAns(res.data)

                let answer = "incorrect";

                let result = res.data
                setQuestion(questions[current_question_index]);
                if (result.relevance_score >= 5) {
                    answer = "correct";
                    setTotalCorrectAnswer((prev) => prev + 1);
                    setTotalQuestionAttempt((prev) => prev + 1);
                } else {
                    answer = "incorrect";
                    setTotalIncorrectAnswer((prev) => prev + 1);
                    setTotalQuestionAttempt((prev) => prev + 1);
                }
                setAnswerResult(answer);
                setQuestionEndTimestamp(question_end_time)
                setQuestionTimeOut("yes")
                setTotalTimeout((prev) => prev + 1);

                let obj = {
                    back_route: "Quizzes",
                };

                navigate("QuizFeedback", obj);
                setIsUploadingAudio(false)
            } else {
                Alert.alert("Something went Wrong.Please try again");
                setIsUploadingAudio(false)
            }
        } catch (error) {
            console.error('Error uploading audio:', error);
            setIsUploadingAudio(false)
        }
    };

    let onEnded = () => {
        if (Platform.OS == "web") {
            if (questions[current_question_index]?.type_code == "LST") {
                Animated.timing(progress, {
                    toValue: 1,
                    duration: question_time_duration * 1000,
                    useNativeDriver: false,
                }).start();

                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }

                intervalRef.current = setInterval(() => {
                    setTimeLeft((prev) => {
                        setNextDisabled(false)

                        if (prev === 1) {
                            setNextDisabled(true)
                            clearInterval(intervalRef.current);
                            setIsUploadingAudio(true)
                            questionTimeOut(moment().format('x'));
                        }
                        return prev - 1;
                    });
                }, 1000);
            }
        }
    };

    return (
        <Layout
            scrollView={false}
            HeaderContent={
                <PageHeader
                    backRoute={"Home"}
                    pageTitle={quiz.name}
                    bgColor={"#17AAF5"}
                    textColor={"#f7faf8"}
                />
            }
            tabbarVisible={false}
        >
            {
                <ScrollView
                    style={[tailwind`w-full mb-9`]}
                    contentContainerStyle={tailwind``}
                >
                    <View style={[tailwind``]}>
                        <View
                            style={[
                                tailwind`w-full`,
                                { flexDirection: "row", alignItems: "center" },
                            ]}
                        >

                            <View
                                style={[
                                    tailwind`font-bold`,
                                    { justifyContent: "space-evenly", marginVertical: 10, width: 130 },
                                ]}
                            >
                                <Text style={tailwind`text-[13px] text-center pr-1`}>
                                    Questions {current_question_index + 1} of {questions.length}
                                </Text>
                            </View>


                            {/* <View
                                style={[
                                    tailwind`mt-1 bg-slate-300 rounded-full h-2 bg-blue-200`,
                                    { flex: 1, flexDirection: "row", width: wd - 90 },
                                ]}
                            >
                                <View
                                    style={[
                                        tailwind`bg-[#17AAF5] h-2 rounded-full`,
                                        {
                                            width:
                                                ((current_question_index + 1) / questions.length) * (wd - 90),
                                        },
                                    ]}
                                ></View>
                            </View> */}

                            {/* <View>
                                <View>
                                    <Text>
                                        question_time_duration : {question_time_duration}
                                    </Text>
                                </View>

                                <View>
                                    <Text>
                                        timeLeft : {timeLeft}
                                    </Text>
                                </View>
                            </View> */}

                            <View style={styles.screen}>
                                <View style={styles.tcontainer}>
                                    <View style={styles.timerContainer}>
                                        <Text style={styles.timeText}>00:{`${question_time_duration - timeLeft}`.slice(-2)}</Text>
                                        <View style={styles.progressBarBackground}>
                                            <Animated.View style={[styles.progressBarForeground, { width: barWidth }]} />
                                        </View>
                                        <Text style={styles.timeText}>00:{`${question_time_duration}`}</Text>
                                    </View>
                                </View>
                            </View>

                        </View>
                        {/* <Text>
                            Qid : {questions[current_question_index]?.id} and  QType : {questions[current_question_index].type_code}
                        </Text> */}
                    </View>
                    {/* Question Type MCQ */}
                    {questions.length > 0 &&
                        questions[current_question_index].type_code == "MCQ" && (
                            <View style={[tailwind`w-full`]}>
                                {questions[current_question_index].instruction && questions[current_question_index].instruction != "#N/A" && (
                                    <View style={[tailwind`w-full bg-slate-200 p-3 mt-2 mb-3`]}>
                                        <Text style={[tailwind`font-semibold`]}>
                                            {questions[current_question_index].instruction}
                                        </Text>
                                    </View>
                                )}
                                {(questions[current_question_index].contents[0].audio ||
                                    questions[current_question_index].contents[0].video) &&
                                    (Platform.OS === "web" ? (
                                        <View style={[tailwind``, styles.player]}>
                                            <ReactPlayer
                                                url={currentAudio}
                                                playing={true}
                                                controls
                                                ref={video}
                                                width={wd - 40}
                                                height="200"
                                                style={styles.plr}
                                                config={{
                                                    file: {
                                                        attributes: {
                                                            preload: "auto",
                                                            controlsList: "nodownload",
                                                        },
                                                    },
                                                }}
                                            />
                                        </View>
                                    ) : (
                                        <>
                                            {
                                                questions[current_question_index].contents[0].video && <Video
                                                    ref={video}
                                                    style={tailwind`w-[${width > 500 ? "500" : width - 50
                                                        }px] h-[200px]`}
                                                    source={{
                                                        uri: "https://storage.googleapis.com/multibhashi-cdn/videos/korean/l2_d6_kor.mp4",
                                                    }}
                                                    useNativeControls
                                                    resizeMode={ResizeMode.CONTAIN}
                                                />
                                            }
                                            {questions[current_question_index].contents[0].audio &&
                                                <View style={[tailwind``]}>
                                                    <Pressable
                                                        style={[
                                                            tailwind`text-center items-center justify-center`,
                                                        ]}
                                                        onPress={() => playAndroidAudio(questions[current_question_index].contents[0].audio)}
                                                    >
                                                        {
                                                            currentAudio &&
                                                            <Ionicons name="pause-circle-outline" size={45} color="#14b8a6" />
                                                        }

                                                        {
                                                            !currentAudio && <Ionicons name="play-circle-outline" size={45} color="#14b8a6" />
                                                        }
                                                    </Pressable>
                                                </View>
                                            }
                                        </>
                                    ))}

                                {questions[current_question_index].contents[0].image && (
                                    <View
                                        style={[tailwind`w-full items-center justify-center mt-3`]}
                                    >
                                        <Image
                                            source={
                                                questions[current_question_index].contents[0].image
                                            }
                                            style={[tailwind``, styles.question_img]}
                                        />
                                    </View>
                                )}

                                {questions[current_question_index].contents[0].text && < View style={[tailwind`py-2 mt-2`]}>
                                    <Text style={[tailwind``]}>
                                        {questions[current_question_index].contents[0].text}
                                    </Text>
                                </View>
                                }

                                {questions[current_question_index].options.map((option, i) => (

                                    <Pressable
                                        key={i}
                                        style={[
                                            tailwind`bg-slate-100 p-1 border border-slate-300  rounded-md my-2 w-full`,
                                        ]}
                                    >
                                        <View
                                            style={[
                                                tailwind``,
                                                { flexDirection: "row", alignItems: "center" },
                                            ]}
                                        >
                                            <RadioButton
                                                value={option}
                                                status={
                                                    checked && checked.id === option.id
                                                        ? "checked"
                                                        : "unchecked"
                                                }
                                                onPress={() => setChecked(option)}
                                            />
                                            <Text style={[tailwind`p-1`]}>{option.text}</Text>
                                        </View>
                                    </Pressable>

                                ))}
                            </View>
                        )}

                    {/* Question Type TF */}
                    {
                        questions.length > 0 &&
                        questions[current_question_index].type_code == "TF" && (
                            <View style={[tailwind`w-full`]}>
                                {questions[current_question_index].instruction && questions[current_question_index].instruction != "#N/A" && (
                                    <View style={[tailwind`w-full bg-slate-200 p-3 mt-2 mb-3`]}>
                                        <Text style={[tailwind`font-semibold`]}>
                                            {questions[current_question_index].instruction}
                                        </Text>
                                    </View>
                                )}
                                {(questions[current_question_index].contents[0].audio ||
                                    questions[current_question_index].contents[0].video) &&
                                    (Platform.OS === "web" ? (
                                        <View style={[tailwind``, styles.player]}>
                                            <ReactPlayer
                                                url={
                                                    questions[current_question_index].contents[0].audio
                                                        ? questions[current_question_index].contents[0].audio
                                                        : questions[current_question_index].contents[0].video
                                                }
                                                controls
                                                ref={video}
                                                width={wd - 40}
                                                height="200"
                                                style={styles.plr}
                                            />
                                        </View>
                                    ) : (
                                        <Video
                                            ref={video}
                                            style={tailwind`w-[${width > 500 ? "500" : width - 50
                                                }px] h-[200px]`}
                                            source={{
                                                uri: questions[current_question_index].contents[0].audio
                                                    ? questions[current_question_index].contents[0].audio
                                                    : questions[current_question_index].contents[0].video,
                                            }}
                                            useNativeControls
                                            resizeMode={ResizeMode.CONTAIN}
                                            isLooping
                                        />
                                    ))}

                                {questions[current_question_index].contents[0].image && (
                                    <View
                                        style={[tailwind`w-full items-center justify-center mt-3`]}
                                    >
                                        <Image
                                            source={
                                                questions[current_question_index].contents[0].image
                                            }
                                            style={[tailwind``, styles.question_img]}
                                        />
                                    </View>
                                )}

                                <View style={[tailwind`py-2 mt-2`]}>
                                    <Text style={[tailwind``]}>
                                        {questions[current_question_index].contents[0].text}
                                    </Text>
                                </View>

                                {questions[current_question_index].options.map((option, i) => (

                                    <Pressable
                                        key={i}
                                        style={[
                                            tailwind`bg-slate-100 p-1 border border-slate-300  rounded-md my-2 w-full`,
                                        ]}
                                    >
                                        <View
                                            style={[
                                                tailwind``,
                                                { flexDirection: "row", alignItems: "center" },
                                            ]}
                                        >
                                            <RadioButton
                                                value={option}
                                                status={
                                                    checked && checked.id === option.id
                                                        ? "checked"
                                                        : "unchecked"
                                                }
                                                onPress={() => setChecked(option)}
                                            />
                                            <Text style={[tailwind`p-1`]}>{option.text}</Text>
                                        </View>
                                    </Pressable>

                                ))}
                            </View>
                        )}

                    {/* Question Type SEQ */}
                    {questions.length > 0 &&
                        questions[current_question_index].type_code == "SEQ" && (
                            <View style={[tailwind`w-full`]}>
                                {questions[current_question_index].instruction && questions[current_question_index].instruction != "#N/A" && (
                                    <View style={[tailwind`w-full bg-slate-200 p-3 mt-2 mb-3`]}>
                                        <Text style={[tailwind`font-semibold`]}>
                                            {questions[current_question_index].instruction}
                                        </Text>
                                    </View>
                                )}
                                {(questions[current_question_index].contents[0].audio ||
                                    questions[current_question_index].contents[0].video) &&
                                    (Platform.OS === "web" ? (
                                        <View style={[tailwind``, styles.player]}>
                                            <ReactPlayer
                                                url={
                                                    questions[current_question_index].contents[0].audio
                                                        ? questions[current_question_index].contents[0].audio
                                                        : questions[current_question_index].contents[0].video
                                                }
                                                controls
                                                ref={video}
                                                width={wd - 40}
                                                height="200"
                                                style={styles.plr}
                                            />
                                        </View>
                                    ) : (
                                        <Video
                                            ref={video}
                                            style={tailwind`w-[${width > 500 ? "500" : width - 50
                                                }px] h-[200px]`}
                                            source={{
                                                uri: questions[current_question_index].contents[0].audio
                                                    ? questions[current_question_index].contents[0].audio
                                                    : questions[current_question_index].contents[0].video,
                                            }}
                                            useNativeControls
                                            resizeMode={ResizeMode.CONTAIN}
                                            isLooping
                                        />
                                    ))}

                                {questions[current_question_index].contents[0].image && (
                                    <View
                                        style={[tailwind`w-full items-center justify-center mt-3`]}
                                    >
                                        <Image
                                            source={
                                                questions[current_question_index].contents[0].image
                                            }
                                            style={[tailwind``, styles.question_img]}
                                        />
                                    </View>
                                )}

                                <View style={[tailwind`py-2 mt-2`]}>
                                    <Text style={[tailwind``]}>
                                        {questions[current_question_index].contents[0].text}
                                    </Text>
                                </View>

                                <View
                                    style={[
                                        tailwind`w-full`,
                                        {
                                            flexDirection: "row",
                                            alignItems: "center",
                                            flexWrap: "wrap",
                                        },
                                    ]}
                                >
                                    {seq_selected_answers.map((optn, i) => (

                                        <Pressable
                                            key={optn.text}
                                            style={[
                                                tailwind`p-1 border border-slate-300  rounded-md m-2`,
                                            ]}
                                            onPress={() => handleSEQAnsRemoved(optn)}
                                        >
                                            <View
                                                style={[
                                                    tailwind``,
                                                    { flexDirection: "row", alignItems: "center" },
                                                ]}
                                            >
                                                <Text style={[tailwind`p-1`]}>{optn.text}</Text>
                                            </View>
                                        </Pressable>

                                    ))}
                                </View>

                                <View
                                    style={[
                                        tailwind`w-full my-5`,
                                        {
                                            height: 1,
                                            borderWidth: 1,
                                            borderColor: "#17AAF5",
                                            borderStyle: "dashed",
                                        },
                                    ]}
                                />

                                <View
                                    style={[
                                        tailwind`w-full`,
                                        {
                                            flexDirection: "row",
                                            alignItems: "center",
                                            flexWrap: "wrap",
                                        },
                                    ]}
                                >
                                    {seq_options.map((option, i) => (

                                        <Pressable
                                            key={i}
                                            style={[
                                                tailwind`p-1 border border-slate-300  rounded-md m-2`,
                                            ]}
                                            onPress={() => handleSEQAns(option)}
                                        >
                                            <View
                                                style={[
                                                    tailwind``,
                                                    { flexDirection: "row", alignItems: "center" },
                                                ]}
                                            >
                                                <Text style={[tailwind`p-1`]}>{option.text}</Text>
                                            </View>
                                        </Pressable>

                                    ))}
                                </View>
                            </View>
                        )}

                    {/* Question Type MTF */}
                    {questions.length > 0 &&
                        questions[current_question_index].type_code == "MTF" && (
                            <View style={[tailwind`w-full`]}>
                                {questions[current_question_index].instruction && questions[current_question_index].instruction != "#N/A" && (
                                    <View style={[tailwind`w-full bg-slate-200 p-3 mt-2 mb-3`]}>
                                        <Text style={[tailwind`font-semibold`]}>
                                            {questions[current_question_index].instruction}
                                        </Text>
                                    </View>
                                )}

                                {questions[current_question_index].contents[0].image && (
                                    <View
                                        style={[tailwind`w-full items-center justify-center mt-3`]}
                                    >
                                        <Image
                                            source={
                                                questions[current_question_index].contents[0].image
                                            }
                                            style={[tailwind``, styles.question_img]}
                                        />
                                    </View>
                                )}

                                <View
                                    style={[
                                        tailwind`w-full bg-slate-200`,
                                        {
                                            flexDirection: "row",
                                            alignItems: "center",
                                            flexWrap: "wrap",
                                        },
                                    ]}
                                >
                                    {questions[current_question_index].contents.map((optn, i) => (
                                        <View key={i} style={[tailwind`flex flex-row w-full`]}>
                                            <View style={[tailwind`w-1/2`]} key={i}>
                                                <View
                                                    key={optn.text}
                                                    style={[
                                                        tailwind`p-1 bg-white border border-white  rounded-md m-2`,
                                                    ]}
                                                >
                                                    <View
                                                        style={[
                                                            tailwind``,
                                                            { flexDirection: "row", alignItems: "center" },
                                                        ]}
                                                    >
                                                        <Text style={[tailwind`p-1`]}>{optn.text}</Text>
                                                    </View>
                                                </View>
                                            </View>

                                            <View style={[tailwind`w-1/2`]}>
                                                <Pressable
                                                    style={[
                                                        tailwind`p-1   rounded-md m-2`,
                                                        {
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            backgroundColor: mtf_selected_answers[i]
                                                                ? "rgb(96 165 250)"
                                                                : "#ffff",
                                                        },
                                                    ]}
                                                    onPress={() =>
                                                        handleMtfAnsRemoved(mtf_selected_answers[i])
                                                    }
                                                >
                                                    {mtf_selected_answers[i] && (
                                                        <Text style={[tailwind`p-1 text-white`]}>
                                                            {mtf_selected_answers[i]?.text}
                                                        </Text>
                                                    )}
                                                    {!mtf_selected_answers[i] && (
                                                        <Text style={[tailwind`px-1 py-3`]}></Text>
                                                    )}
                                                </Pressable>
                                            </View>
                                        </View>
                                    ))}
                                </View>

                                <View
                                    style={[
                                        tailwind`w-full bg-slate-200 mt-2`,
                                        {
                                            flexDirection: "row",
                                            alignItems: "center",
                                            flexWrap: "wrap",
                                        },
                                    ]}
                                >
                                    <View style={[tailwind``]}>
                                        {mtf_options.map((optn, i) => (

                                            <Pressable
                                                key={optn.text}
                                                style={[
                                                    tailwind`p-1 bg-white border border-white  rounded-md m-2`,
                                                ]}
                                                onPress={() => handleMtfAns(optn)}
                                            >
                                                <View
                                                    style={[
                                                        tailwind``,
                                                        { flexDirection: "row", alignItems: "center" },
                                                    ]}
                                                >
                                                    <Text style={[tailwind`p-1`]}>{optn.text}</Text>
                                                </View>
                                            </Pressable>

                                        ))}
                                    </View>
                                </View>
                            </View>
                        )}

                    {/* Question Type RC */}
                    {questions.length > 0 &&
                        questions[current_question_index].type_code == "RC" && (
                            <View style={[tailwind`w-full`]}>
                                {questions[current_question_index].instruction && questions[current_question_index].instruction != "#N/A" && (
                                    <View style={[tailwind`w-full bg-slate-200 p-3 mt-2 mb-3`]}>
                                        <Text style={[tailwind`font-semibold`]}>
                                            {questions[current_question_index].instruction}
                                        </Text>
                                    </View>
                                )}


                                {questions[current_question_index].paragraph && questions[current_question_index].paragraph != "#N/A" && (
                                    <View style={[tailwind`w-full bg-slate-200 p-3 mt-2 mb-3 text-justify`]}>
                                        <ScrollView vertical={true} style={[tailwind`max-h-40`]} showsHorizontalScrollIndicator={false}>
                                            <Text style={[tailwind`text-justify`]}>
                                                {questions[current_question_index].paragraph}
                                            </Text>
                                        </ScrollView>
                                    </View>
                                )}

                                {(questions[current_question_index].contents[0].audio ||
                                    questions[current_question_index].contents[0].video) &&
                                    (Platform.OS === "web" ? (
                                        <View style={[tailwind``, styles.player]}>
                                            <ReactPlayer
                                                url={
                                                    questions[current_question_index].contents[0].audio
                                                        ? questions[current_question_index].contents[0].audio
                                                        : questions[current_question_index].contents[0].video
                                                }
                                                playing={true}
                                                controls
                                                ref={video}
                                                width={wd - 40}
                                                height="200"
                                                style={styles.plr}
                                                config={{
                                                    file: {
                                                        attributes: {
                                                            preload: "auto",
                                                            controlsList: "nodownload",
                                                        },
                                                    },
                                                }}
                                            />
                                        </View>
                                    ) : (
                                        <>
                                            {
                                                questions[current_question_index].contents[0].video && <Video
                                                    ref={video}
                                                    style={tailwind`w-[${width > 500 ? "500" : width - 50
                                                        }px] h-[200px]`}
                                                    source={{
                                                        uri: "https://storage.googleapis.com/multibhashi-cdn/videos/korean/l2_d6_kor.mp4",
                                                    }}
                                                    useNativeControls
                                                    resizeMode={ResizeMode.CONTAIN}
                                                />
                                            }
                                            {questions[current_question_index].contents[0].audio &&
                                                <View style={[tailwind``]}>
                                                    <Pressable
                                                        style={[
                                                            tailwind`text-center items-center justify-center`,
                                                        ]}
                                                        onPress={() => playAndroidAudio(questions[current_question_index].contents[0].audio)}
                                                    >
                                                        {
                                                            currentAudio &&
                                                            <Ionicons name="pause-circle-outline" size={45} color="#14b8a6" />
                                                        }

                                                        {
                                                            !currentAudio && <Ionicons name="play-circle-outline" size={45} color="#14b8a6" />
                                                        }
                                                    </Pressable>
                                                </View>
                                            }
                                        </>
                                    ))}

                                {questions[current_question_index].contents[0].image && (
                                    <View
                                        style={[tailwind`w-full items-center justify-center mt-3`]}
                                    >
                                        <Image
                                            source={
                                                questions[current_question_index].contents[0].image
                                            }
                                            style={[tailwind``, styles.question_img]}
                                        />
                                    </View>
                                )}

                                {(!questions[current_question_index].contents[0].audio &&
                                    !questions[current_question_index].contents[0].video) && < View style={[tailwind`py-2 mt-2`]}>
                                        <Text style={[tailwind``]}>
                                            {questions[current_question_index].contents[0].text}
                                        </Text>
                                    </View>
                                }

                                {questions[current_question_index].options.map((option, i) => (

                                    <Pressable
                                        key={i}
                                        style={[
                                            tailwind`bg-slate-100 p-1 border border-slate-300  rounded-md my-2 w-full ${(i + 1 == questions[current_question_index].options.length) ? "mb-6" : ""}`,
                                        ]}
                                    >
                                        <View
                                            style={[
                                                tailwind``,
                                                { flexDirection: "row", alignItems: "center" },
                                            ]}
                                        >
                                            <RadioButton
                                                value={option}
                                                status={
                                                    checked && checked.id === option.id
                                                        ? "checked"
                                                        : "unchecked"
                                                }
                                                onPress={() => setChecked(option)}
                                            />
                                            <Text style={[tailwind`p-1`]}>{option.text}</Text>
                                        </View>
                                    </Pressable>

                                ))}
                            </View>
                        )}

                    {/* Question Type SQ */}
                    {questions.length > 0 &&
                        questions[current_question_index].type_code == "SQ" && (
                            <View style={[tailwind`w-full`]}>
                                {questions[current_question_index].instruction && questions[current_question_index].instruction != "#N/A" && (
                                    <View style={[tailwind`w-full bg-slate-200 p-3 mt-2 mb-3`]}>
                                        <Text style={[tailwind`font-semibold`]}>
                                            {questions[current_question_index].instruction}
                                        </Text>
                                    </View>
                                )}

                                {(questions[current_question_index].contents[0].audio ||
                                    questions[current_question_index].contents[0].video) &&
                                    (Platform.OS === "web" ? (
                                        <View style={[tailwind``, styles.player]}>
                                            <ReactPlayer
                                                url={
                                                    questions[current_question_index].contents[0].audio
                                                        ? questions[current_question_index].contents[0].audio
                                                        : questions[current_question_index].contents[0].video
                                                }
                                                playing={true}
                                                controls
                                                ref={video}
                                                width={wd - 40}
                                                height="200"
                                                style={styles.plr}
                                                config={{
                                                    file: {
                                                        attributes: {
                                                            preload: "auto",
                                                            controlsList: "nodownload",
                                                        },
                                                    },
                                                }}
                                            />
                                        </View>
                                    ) : (
                                        <>
                                            {
                                                questions[current_question_index].contents[0].video && <Video
                                                    ref={video}
                                                    style={tailwind`w-[${width > 500 ? "500" : width - 50
                                                        }px] h-[200px]`}
                                                    source={{
                                                        uri: "https://storage.googleapis.com/multibhashi-cdn/videos/korean/l2_d6_kor.mp4",
                                                    }}
                                                    useNativeControls
                                                    resizeMode={ResizeMode.CONTAIN}
                                                />
                                            }
                                            {questions[current_question_index].contents[0].audio &&
                                                <View style={[tailwind``]}>
                                                    <Pressable
                                                        style={[
                                                            tailwind`text-center items-center justify-center`,
                                                        ]}
                                                        onPress={() => playAndroidAudio(questions[current_question_index].contents[0].audio)}
                                                    >
                                                        {
                                                            currentAudio &&
                                                            <Ionicons name="pause-circle-outline" size={45} color="#14b8a6" />
                                                        }

                                                        {
                                                            !currentAudio && <Ionicons name="play-circle-outline" size={45} color="#14b8a6" />
                                                        }
                                                    </Pressable>
                                                </View>
                                            }
                                        </>
                                    ))}

                                {questions[current_question_index].contents[0].image && (
                                    <View
                                        style={[tailwind`w-full items-center justify-center mt-3`]}
                                    >
                                        <Image
                                            source={
                                                questions[current_question_index].contents[0].image
                                            }
                                            style={[tailwind``, styles.question_img]}
                                        />
                                    </View>
                                )}

                                {(!questions[current_question_index].contents[0].audio &&
                                    !questions[current_question_index].contents[0].video) && < View style={[tailwind`py-2 mt-2`]}>
                                        <Text style={[tailwind``]}>
                                            {questions[current_question_index].contents[0].text}
                                        </Text>
                                    </View>
                                }

                                <View style={[tailwind`pt-2`]}>
                                    {isRecording &&
                                        <View style={[tailwind`flex justify-center`, styles.recordingContainer]}>

                                            <View style={[tailwind`flex items-center justify-center`]}>
                                                <Ionicons name="mic-outline" size={40} color="#4F4F4F" style={[
                                                    tailwind`items-center justify-center`]} />
                                            </View>
                                            <Text style={styles.timer}>{formattedTime}</Text>

                                            <View style={[tailwind`flex flex-row items-center justify-center w-full `]}>

                                                {!isPaused &&
                                                    <Pressable
                                                        style={[
                                                            tailwind``
                                                        ]}
                                                        onPress={() => pauseRecording()}
                                                    >
                                                        <Text
                                                            style={[
                                                                styles.buttonText,
                                                                {
                                                                    color: "#4F4F4F",
                                                                    textAlignVertical: 'center',
                                                                },
                                                            ]}
                                                        >
                                                            <Ionicons name="pause-circle-outline" size={30} color="#4F4F4F" style={[
                                                                tailwind``]} />
                                                        </Text>
                                                    </Pressable>
                                                }

                                                {isPaused &&
                                                    <Pressable
                                                        style={[
                                                            tailwind``
                                                        ]}
                                                        onPress={() => resumeRecording()}
                                                    >
                                                        <Text
                                                            style={[
                                                                styles.buttonText,
                                                                {
                                                                    color: "#4F4F4F",
                                                                    textAlignVertical: 'center',
                                                                },
                                                            ]}
                                                        >
                                                            <Ionicons name="play-circle-outline" size={30} color="#4F4F4F" style={[
                                                                tailwind``]} />
                                                        </Text>
                                                    </Pressable>
                                                }

                                                {
                                                    <Pressable
                                                        style={[
                                                            tailwind``
                                                        ]}
                                                        onPress={() => stopRecording()}
                                                    >
                                                        <Text
                                                            style={[
                                                                styles.buttonText,
                                                                {
                                                                    color: "#4F4F4F",
                                                                    textAlignVertical: 'center',
                                                                },
                                                            ]}
                                                        >
                                                            <Ionicons name="stop-circle-outline" size={30} color="#4F4F4F" style={[
                                                                tailwind``]} />
                                                        </Text>
                                                    </Pressable>
                                                }
                                            </View>
                                        </View>
                                    }

                                    {audioURL &&
                                        <View>
                                            <Text style={[tailwind`font-semibold py-2`]}>Recorded Audio</Text>
                                            <View style={[tailwind`flex flex-row items-center justify-center w-full `]}>
                                                <View style={[tailwind`flex justify-center my-3`]}>
                                                    <audio controls src={audioURL} />
                                                </View>
                                                <View style={[tailwind`flex justify-center items-center`]}>
                                                    {!is_uploading_audio &&
                                                        <Pressable
                                                            style={[
                                                                tailwind`items-center`
                                                            ]}
                                                            onPress={() => deleteRecording()}
                                                        >
                                                            <Ionicons name="close-circle-outline" size={30} color="#4F4F4F" style={[
                                                                tailwind``]} />
                                                        </Pressable>
                                                    }
                                                </View>
                                            </View>
                                        </View>
                                    }
                                </View>
                            </View>
                        )}

                    {/* Question Type WQ */}
                    {questions.length > 0 &&
                        questions[current_question_index].type_code == "WQ" && (
                            <View style={[tailwind`w-full`]}>
                                {questions[current_question_index].instruction && questions[current_question_index].instruction != "#N/A" && (
                                    <View style={[tailwind`w-full bg-slate-200 p-3 mt-2 mb-3`]}>
                                        <Text style={[tailwind`w-full font-semibold`]}>
                                            {questions[current_question_index].instruction.trim()}
                                        </Text>
                                    </View>
                                )}

                                {(questions[current_question_index].contents[0].audio ||
                                    questions[current_question_index].contents[0].video) &&
                                    (Platform.OS === "web" ? (
                                        <View style={[tailwind``, styles.player]}>
                                            <ReactPlayer
                                                url={
                                                    questions[current_question_index].contents[0].audio
                                                        ? questions[current_question_index].contents[0].audio
                                                        : questions[current_question_index].contents[0].video
                                                }
                                                playing={true}
                                                controls
                                                ref={video}
                                                width={wd - 40}
                                                height="200"
                                                style={styles.plr}
                                                config={{
                                                    file: {
                                                        attributes: {
                                                            preload: "auto",
                                                            controlsList: "nodownload",
                                                        },
                                                    },
                                                }}
                                            />
                                        </View>
                                    ) : (
                                        <>
                                            {
                                                questions[current_question_index].contents[0].video && <Video
                                                    ref={video}
                                                    style={tailwind`w-[${width > 500 ? "500" : width - 50
                                                        }px] h-[200px]`}
                                                    source={{
                                                        uri: "https://storage.googleapis.com/multibhashi-cdn/videos/korean/l2_d6_kor.mp4",
                                                    }}
                                                    useNativeControls
                                                    resizeMode={ResizeMode.CONTAIN}
                                                />
                                            }
                                            {questions[current_question_index].contents[0].audio &&
                                                <View style={[tailwind``]}>
                                                    <Pressable
                                                        style={[
                                                            tailwind`text-center items-center justify-center`,
                                                        ]}
                                                        onPress={() => playAndroidAudio(questions[current_question_index].contents[0].audio)}
                                                    >
                                                        {
                                                            currentAudio &&
                                                            <Ionicons name="pause-circle-outline" size={45} color="#14b8a6" />
                                                        }

                                                        {
                                                            !currentAudio && <Ionicons name="play-circle-outline" size={45} color="#14b8a6" />
                                                        }
                                                    </Pressable>
                                                </View>
                                            }
                                        </>
                                    ))}

                                {questions[current_question_index].contents[0].image && (
                                    <View
                                        style={[tailwind`w-full items-center justify-center mt-3`]}
                                    >
                                        <Image
                                            source={
                                                questions[current_question_index].contents[0].image
                                            }
                                            style={[tailwind``, styles.question_img]}
                                        />
                                    </View>
                                )}

                                {(!questions[current_question_index].contents[0].audio &&
                                    !questions[current_question_index].contents[0].video) && < View style={[tailwind`py-2 mt-2`]}>
                                        <Text style={[tailwind``]}>
                                            {questions[current_question_index].contents[0].text}
                                        </Text>
                                    </View>
                                }

                                <View style={[tailwind`pt-2`]}>
                                    <Text style={[tailwind`font-semibold py-2`]}>Answer</Text>
                                    <View style={styles.input_container}>
                                        <TextInput
                                            style={[styles.textarea]}
                                            multiline={true}
                                            numberOfLines={6}
                                            placeholder="Type your answer here..."
                                            value={wq_text_answer}
                                            onChangeText={setWqTextAnswer}
                                        />
                                    </View>

                                </View>

                            </View>
                        )}

                    {/* Question Type LST */}
                    {questions.length > 0 &&
                        questions[current_question_index].type_code == "LST" && (
                            <View style={[tailwind`w-full`]}>
                                {questions[current_question_index].instruction && questions[current_question_index].instruction != "#N/A" && (
                                    <View style={[tailwind`w-full bg-slate-200 p-3 mt-2 mb-3`]}>
                                        <Text style={[tailwind`font-semibold`]}>
                                            {questions[current_question_index].instruction}
                                        </Text>
                                    </View>
                                )}

                                {(questions[current_question_index].contents[0].audio ||
                                    questions[current_question_index].contents[0].video) &&
                                    (Platform.OS === "web" ? (
                                        <View style={[tailwind``, styles.player]}>
                                            <ReactPlayer
                                                url={currentAudio}
                                                playing={true}
                                                controls
                                                ref={video}
                                                width={wd - 40}
                                                height="200"
                                                onEnded={onEnded}
                                                style={styles.plr}
                                                config={{
                                                    file: {
                                                        attributes: {
                                                            preload: "auto",
                                                            controlsList: "nodownload",
                                                        },
                                                    },
                                                }}
                                            />
                                        </View>
                                    ) : (
                                        <>
                                            {
                                                questions[current_question_index].contents[0].video && <Video
                                                    ref={video}
                                                    style={tailwind`w-[${width > 500 ? "500" : width - 50
                                                        }px] h-[200px]`}
                                                    source={{
                                                        uri: "https://storage.googleapis.com/multibhashi-cdn/videos/korean/l2_d6_kor.mp4",
                                                    }}
                                                    useNativeControls
                                                    resizeMode={ResizeMode.CONTAIN}
                                                />
                                            }
                                            {questions[current_question_index].contents[0].audio &&
                                                <View style={[tailwind``]}>
                                                    <Pressable
                                                        style={[
                                                            tailwind`text-center items-center justify-center`,
                                                        ]}
                                                        onPress={() => playAndroidAudio(questions[current_question_index].contents[0].audio)}
                                                    >
                                                        {
                                                            currentAudio &&
                                                            <Ionicons name="pause-circle-outline" size={45} color="#14b8a6" />
                                                        }

                                                        {
                                                            !currentAudio && <Ionicons name="play-circle-outline" size={45} color="#14b8a6" />
                                                        }
                                                    </Pressable>
                                                </View>
                                            }
                                        </>
                                    ))}

                                {questions[current_question_index].contents[0].image && (
                                    <View
                                        style={[tailwind`w-full items-center justify-center mt-3`]}
                                    >
                                        <Image
                                            source={
                                                questions[current_question_index].contents[0].image
                                            }
                                            style={[tailwind``, styles.question_img]}
                                        />
                                    </View>
                                )}

                                {< View style={[tailwind`py-2 mt-2`]}>
                                    <Text style={[tailwind``]}>
                                        {questions[current_question_index].contents[0].text}
                                    </Text>
                                </View>
                                }

                                {questions[current_question_index].options.map((option, i) => (

                                    <Pressable
                                        key={i}
                                        style={[
                                            tailwind`bg-slate-100 p-1 border border-slate-300  rounded-md my-2 w-full ${(i + 1 == questions[current_question_index].options.length) ? "mb-6" : ""}`,
                                        ]}
                                    >
                                        <View
                                            style={[
                                                tailwind``,
                                                { flexDirection: "row", alignItems: "center" },
                                            ]}
                                        >
                                            <RadioButton
                                                value={option}
                                                status={
                                                    checked && checked.id === option.id
                                                        ? "checked"
                                                        : "unchecked"
                                                }
                                                onPress={() => setChecked(option)}
                                            />
                                            <Text style={[tailwind`p-1`]}>{option.text}</Text>
                                        </View>
                                    </Pressable>

                                ))}
                            </View>
                        )}

                </ScrollView>
            }

            <View
                style={[
                    tailwind`text-center items-center justify-center`,
                    {
                        position: "fixed",
                        bottom: 0,
                        width: wd - 40,
                    },
                ]}
            >

                {questions[current_question_index].type_code == "SEQ" && (
                    <>
                        {questions[current_question_index].options.length !=
                            seq_selected_answers.length && (
                                <Pressable
                                    style={[
                                        tailwind`absolute bottom-[20px] w-full`,
                                        styles.buttonStyle,
                                        {
                                            backgroundColor: "#EFEFEF",
                                        },
                                    ]}
                                    onPress={() => checkAnswer()}
                                >
                                    <Text
                                        style={[
                                            styles.buttonText,
                                            {
                                                color: "#4F4F4F",
                                            },
                                        ]}
                                    >
                                        {" "}
                                        Next
                                    </Text>
                                </Pressable>
                            )}

                        {questions[current_question_index].options.length ==
                            seq_selected_answers.length && (
                                <Pressable
                                    style={[
                                        tailwind`absolute bottom-[20px] w-full`,
                                        styles.buttonStyle,
                                        {
                                            backgroundColor: "#3EBD3E",
                                        },
                                    ]}
                                    onPress={() => checkAnswer()}
                                >
                                    <Text
                                        style={[
                                            styles.buttonText,
                                            {
                                                color: "#FFFFFF",
                                            },
                                        ]}
                                    >
                                        {" "}
                                        Next
                                    </Text>
                                </Pressable>
                            )}
                    </>
                )}

                {questions[current_question_index].type_code == "MTF" && (
                    <>

                        {mtf_options.length == 0 && (
                            <Pressable
                                style={[
                                    tailwind`absolute bottom-[20px] w-full`,
                                    styles.buttonStyle,
                                    {
                                        backgroundColor: "#3EBD3E",
                                    },
                                ]}
                                onPress={() => checkAnswer()}
                            >
                                <Text
                                    style={[
                                        styles.buttonText,
                                        {
                                            color: "#FFFFFF",
                                        },
                                    ]}
                                >
                                    Next
                                </Text>
                            </Pressable>
                        )}
                    </>
                )}

                {(questions[current_question_index].type_code == "MCQ" ||
                    questions[current_question_index].type_code == "TF" ||
                    questions[current_question_index].type_code == "RC" ||
                    questions[current_question_index].type_code == "LST") && (
                        <>
                            {checked == null && (
                                <Pressable
                                    style={[
                                        tailwind`absolute bottom-[20px] w-full`,
                                        styles.buttonStyle,
                                        {
                                            backgroundColor: "#EFEFEF",
                                        },
                                    ]}
                                    onPress={() => checkAnswer()}
                                >
                                    <Text
                                        style={[
                                            styles.buttonText,
                                            {
                                                color: "#4F4F4F",
                                            },
                                        ]}
                                    >
                                        Next
                                    </Text>
                                </Pressable>
                            )}

                            {checked != null && (
                                <Pressable
                                    style={[
                                        tailwind`absolute bottom-[20px] w-full`,
                                        styles.buttonStyle,
                                        {
                                            backgroundColor: "#3EBD3E",
                                        },
                                    ]}
                                    onPress={() => checkAnswer()}
                                >
                                    <Text
                                        style={[
                                            styles.buttonText,
                                            {
                                                color: "#FFFFFF",
                                            },
                                        ]}
                                    >
                                        Next
                                    </Text>
                                </Pressable>
                            )}
                        </>
                    )}

                {(questions[current_question_index].type_code == "SQ") && (
                    <>
                        {checked == null && (
                            <>
                                {!isRecording && !audioURL &&
                                    <Pressable
                                        style={[
                                            tailwind`absolute bottom-[20px] w-full`,
                                            styles.buttonStyle,
                                            {
                                                backgroundColor: "#EFEFEF",
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            },
                                        ]}
                                        onPress={() => startRecording()}
                                    >
                                        <Text
                                            style={[
                                                styles.buttonText,
                                                {
                                                    color: "#4F4F4F",
                                                    textAlignVertical: 'center',
                                                },
                                            ]}
                                        >
                                            <Ionicons name="mic-outline" size={17} color="#4F4F4F" style={[
                                                tailwind`mr-2`]} />

                                            <Text style={[
                                                tailwind`mb-1`]} >Record</Text>
                                        </Text>
                                    </Pressable>
                                }
                                {
                                    isRecording &&

                                    <Pressable
                                        style={[
                                            tailwind`absolute bottom-[20px] w-full`,
                                            styles.buttonStyle,
                                            {
                                                backgroundColor: "#EFEFEF",
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            },
                                        ]}
                                        onPress={() => stopRecording()}
                                    >
                                        <Text
                                            style={[
                                                styles.buttonText,
                                                {
                                                    color: "#4F4F4F",
                                                    textAlignVertical: 'center',
                                                },
                                            ]}
                                        >
                                            <Ionicons name="mic-outline" size={17} color="#4F4F4F" style={[
                                                tailwind`mr-2`]} />

                                            <Text style={[
                                                tailwind`mb-1`]} >Stop Recording</Text>
                                        </Text>
                                    </Pressable>
                                }
                            </>
                        )}

                        {audioURL && (
                            <Pressable
                                style={[
                                    tailwind`absolute bottom-[20px] w-full`,
                                    styles.buttonStyle,
                                    {
                                        backgroundColor: "#3EBD3E",
                                    },
                                ]}
                                onPress={() => uploadAudio()}
                                disabled={is_uploading_audio || next_disabled}
                            >
                                {!is_uploading_audio && <Text
                                    style={[
                                        styles.buttonText,
                                        {
                                            color: "#FFFFFF",
                                        },
                                    ]}
                                >
                                    Next
                                </Text>}

                                {is_uploading_audio && <Text
                                    style={[
                                        styles.buttonText,
                                        {
                                            color: "#FFFFFF",
                                        },
                                    ]}
                                >
                                    Loading...
                                </Text>
                                }
                            </Pressable>
                        )}
                    </>
                )}

                {(questions[current_question_index].type_code == "WQ") && (
                    <>
                        {wq_text_answer == "" && (
                            <Pressable
                                style={[
                                    tailwind`absolute bottom-[20px] w-full`,
                                    styles.buttonStyle,
                                    {
                                        backgroundColor: "#EFEFEF",
                                    },
                                ]}
                            >
                                <Text
                                    style={[
                                        styles.buttonText,
                                        {
                                            color: "#4F4F4F",
                                        },
                                    ]}
                                >
                                    Next
                                </Text>
                            </Pressable>
                        )}

                        {wq_text_answer != "" && (
                            <Pressable
                                style={[
                                    tailwind`absolute bottom-[20px] w-full`,
                                    styles.buttonStyle,
                                    {
                                        backgroundColor: "#3EBD3E",
                                    },
                                ]}
                                onPress={() => checkWQAns()}
                                disabled={is_uploading_audio}
                            >
                                {!is_uploading_audio && <Text
                                    style={[
                                        styles.buttonText,
                                        {
                                            color: "#FFFFFF",
                                        },
                                    ]}
                                >
                                    Next
                                </Text>}

                                {is_uploading_audio && <Text
                                    style={[
                                        styles.buttonText,
                                        {
                                            color: "#FFFFFF",
                                        },
                                    ]}
                                >
                                    Loading...
                                </Text>
                                }
                            </Pressable>
                        )}
                    </>
                )}

            </View>
        </Layout >
    );
}

const styles = StyleSheet.create({
    question_img: {
        resizeMode: "contain",
        width: wd - 40,
        height: 200,
        // objectFit: "contain",
    },
    player: {
        // width: wd - 40,
        height: 200,
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        backgroundColor: "transparent"
    },
    plr: {
        width: wd - 40,
        height: "300",
        backgroundColor: "transparent"
    },
    loadercontainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        verticalAlign: "middle",
        marginTop: 50,
        marginBottom: "50%",
    },
    activityIndicator: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        verticalAlign: "middle",
    },
    buttonStyle: {
        borderRadius: 5,
        paddingVertical: 10,
        paddingHorizontal: 18,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // marginVertical: 30,
    },
    buttonText: {
        fontWeight: "bold",
        // color: "#fff",
    },
    card_sec1: {
        width: "20%",
    },
    card_sec2: {
        width: "80%",
    },

    container: {
        flexDirection: "row",
        // justifyContent: 'flex-end',
        // justifyContent: 'flex-center',
        flexWrap: "wrap",
        // alignContent: 'space-around',
        alignItems: "flex-start",
    },

    input_container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        // padding: 20,
    },
    textarea: {
        width: '100%',
        borderColor: '#ccc',
        borderWidth: 1,
        borderRadius: 8,
        padding: 10,
        fontSize: 16,
        textAlignVertical: 'top', // Aligns text to the top of the input field
        backgroundColor: "#EFEFEF"
    },


    recordingContainer: {
        flex: 1,
        backgroundColor: '#FFFFFF',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    timer: {
        fontSize: 30,
        fontWeight: 'bold',
        marginBottom: 20,
    },



    screen: {
        flex: 1,
        justifyContent: "center",
        // padding: 20,
        // backgroundColor: "#f0f0f0",
    },
    tcontainer: {
        width: "100%",
        alignItems: "center",
    },
    questionText: {
        fontSize: 13,
        // fontWeight: "bold",
        marginBottom: 10,
    },
    timerContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
    },
    timeText: {
        fontSize: 13,
        color: "#333",
    },
    progressBarBackground: {
        flex: 1,
        height: 10,
        backgroundColor: "#d0e4fc",
        borderRadius: 5,
        marginHorizontal: 10,
        overflow: "hidden",
    },
    progressBarForeground: {
        height: "100%",
        backgroundColor: "#007bff",
        borderRadius: 5,
    },
});
