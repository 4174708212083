import React, { useState, useEffect } from "react";
import {
    TouchableOpacity,
    View,
    ActivityIndicator,
    StyleSheet,
    FlatList,
    TextInput,
    Pressable,
    Keyboard,
} from "react-native";
import tailwind from "twrnc";
import { Feather } from "@expo/vector-icons";
import FilterModal from "../common/filter_modal";
import useClassesFilter from "../common/context";
import { TEACHERS } from "../../../../../api/instance";
import useUser from "../../../../../context/user";
import Text from "../../../../../components/Text";
import ScheduledItem from "./scheduled_items";
import moment from "moment";
import { height, isWeb } from "../../../../../constants/constants";
import { Ionicons } from "@expo/vector-icons";

const Scheduled = () => {
    let { user } = useUser();
    const [modalOpen, setModalOpen] = useState(false);
    let [scheduled_calls, setScheduledCalls] = useState([]);
    let [loading, setLoading] = useState(true);
    let [currentPage, setCurrentPage] = useState(1);
    let [lastPage, setLastPage] = useState(0);
    let [loadMoreLoader, setLoadMoreLoader] = useState(false);
    let [search, setSearch] = useState("");
    let [isSearched, setIsSearched] = useState(false);

    // Access Range here
    const { range, status, setStatuses, teacherSelected, slot, categorySelected, setStDateValidate, setEdDateValidate } =
        useClassesFilter();

    useEffect(() => {
        setStDateValidate(0)
        setEdDateValidate(0)
        if (user.is_live_teacher) {
            getTeacherScheduledCalls()
        } else {
            getScheduledCalls()
        }

    }, []);

    async function getTeacherScheduledCalls(getMore = false) {
        let params = {
            phone: user.phone,
            app_user_id: user._id
        };

        if (range && range.startDate && range.endDate) {
            params.start_date = moment(range.startDate).format("DD-MM-YYYY");
            params.end_date = moment(range.endDate).format("DD-MM-YYYY");
        }

        if (teacherSelected) {
            params.teacher_id = teacherSelected.id;
        }

        if (slot) {
            params.slot = slot;
        }

        if (categorySelected) {
            params.category = categorySelected;
        }

        if (search && !categorySelected) {
            params.search = search;
            params.category = {
                "name": "Group",
                "slug": "WEBINAR"
            }
        }

        await TEACHERS.post(`/teacher/scheduled/calls`, params)
            .then((response) => {
                setScheduledCalls(response.data.data);
                if (response?.data?.data.length >= 900) {
                    let dt = response.data.data
                    setEdDateValidate(dt[0].slot)
                    let len = dt.length
                    setStDateValidate(dt[len - 1].slot)
                } else {
                    setStDateValidate(0)
                    setEdDateValidate(0)
                }
                setLoading(false);
                setLoadMoreLoader(false);
            })
            .catch((error) => {
                console.log(error.response.data);
                setLoading(false);
                setLoadMoreLoader(false);
            });
    }

    async function getScheduledCalls(getMore = false) {
        let params = {
            phone: user.phone
        };

        if (range && range.startDate && range.endDate) {
            params.start_date = moment(range.startDate).format("DD-MM-YYYY");
            params.end_date = moment(range.endDate).format("DD-MM-YYYY");
        }

        if (teacherSelected) {
            params.teacher_id = teacherSelected.id;
        }

        if (slot) {
            params.slot = slot;
        }

        if (categorySelected) {
            params.category = categorySelected;
        }

        if (search && !categorySelected) {
            params.search = search;
            params.category = {
                "name": "Group",
                "slug": "WEBINAR"
            }
        }

        await TEACHERS.post(`/scheduled/calls`, params)
            .then((response) => {
                setScheduledCalls(response.data.data);
                if (response?.data?.data.length >= 900) {
                    let dt = response.data.data
                    setEdDateValidate(dt[0].slot)
                    let len = dt.length
                    setStDateValidate(dt[len - 1].slot)
                } else {
                    setStDateValidate(0)
                    setEdDateValidate(0)
                }
                setLoading(false);
                setLoadMoreLoader(false);
            })
            .catch((error) => {
                console.log(error.response.data);
                setLoading(false);
                setLoadMoreLoader(false);
            });
    }

    let renderLoader = () => {
        return loadMoreLoader ? (
            <View>
                <ActivityIndicator />
            </View>
        ) : null;
    };

    let filterApply = () => {
        setScheduledCalls([])
        setLoadMoreLoader(false);
        setLoading(true);
        if (user.is_live_teacher) {
            getTeacherScheduledCalls()
        } else {
            getScheduledCalls()
        }
    };

    let handleSearch = () => {
        Keyboard.dismiss();
        if (search) {
            setIsSearched(true);
            setScheduledCalls([]);
            setLoadMoreLoader(false);
            setLoading(true);
            if (user.is_live_teacher) {
                getTeacherScheduledCalls()
            } else {
                getScheduledCalls()
            }
        } else {
            setIsSearched(false);
        }
    };

    let handleSearchClear = () => {
        Keyboard.dismiss();
        setSearch("");
        search = "";
        setIsSearched(false);
        setScheduledCalls([]);
        setLoadMoreLoader(false);
        setLoading(true);
        if (user.is_live_teacher) {
            getTeacherScheduledCalls()
        } else {
            getScheduledCalls()
        }
    };

    let statusUpdatedSuccess = () => {
        if (user.is_live_teacher) {
            getTeacherScheduledCalls()
        } else {
            getScheduledCalls()
        }
    };
    return (
        <View style={tailwind`w-full mt-5`}>
            <View style={[tailwind`flex-row w-full`, styles.inputContainer]}>
                <View style={{ width: isSearched ? "95%" : "100%" }}>
                    <TextInput
                        style={styles.inputStyle}
                        placeholder={"Search by Batch Name or Topic Name"}
                        value={search}
                        onChangeText={(item) => setSearch(item)}
                    />
                    <Pressable onPress={handleSearch} style={styles.icon}>
                        <Ionicons name="search" size={20} color="#2096F3" />
                    </Pressable>
                </View>

                {isSearched && (
                    <View style={{ width: "5%" }}>
                        <Pressable onPress={handleSearchClear} style={styles.icon2}>
                            <Ionicons name="close" size={20} color="#ef4444" />
                        </Pressable>
                    </View>
                )}
            </View>
            <FilterModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                getClasses={filterApply}
            />
            <TouchableOpacity
                onPress={() => setModalOpen(true)}
                style={tailwind`border border-[#9E9E9E] min-h-[80px] rounded px-5 py-3 w-full bg-[#fafafa] relative`}
            >
                <Feather
                    style={tailwind`absolute right-[10px] top-[10px]`}
                    name="filter"
                    size={24}
                    color="black"
                />

                <View style={[tailwind`flex-row`]}>
                    {range && range.startDate && range.endDate && (
                        <View style={[tailwind`flex-row`]}>
                            <Text
                                style={tailwind`text-center bg-blue-400 text-white text-base py-0 px-1 rounded dark:bg-blue-400 text-[12px]`}
                            >
                                {moment(range.startDate).format("DD-MM-YYYY")}
                            </Text>
                            <Text style={tailwind`text-center mx-1`}>-</Text>
                            <Text
                                style={tailwind`text-center bg-blue-400 text-white text-base py-0 px-1 rounded dark:bg-blue-400 text-[12px]`}
                            >
                                {moment(range.endDate).format("DD-MM-YYYY")}
                            </Text>
                        </View>
                    )}

                    {slot && (
                        <Text
                            style={tailwind`text-center bg-blue-400 text-white text-base py-0 px-1 rounded dark:bg-blue-400 text-[12px] mx-2`}
                        >
                            {slot}
                        </Text>
                    )}

                    {teacherSelected && (
                        <Text
                            style={tailwind`text-center bg-blue-400 text-white text-base py-0 px-1 rounded dark:bg-blue-400 text-[12px] mx-2`}
                        >
                            {teacherSelected.name}
                        </Text>
                    )}


                    {categorySelected && (
                        <Text
                            style={tailwind`text-center bg-blue-400 text-white text-base py-0 px-1 rounded dark:bg-blue-400 text-[12px] mx-2`}
                        >
                            {categorySelected.name}
                        </Text>
                    )}
                </View>



                {(!range.startDate && !slot && !teacherSelected && !!categorySelected) && (
                    <View style={[tailwind`flex-row`]}>
                        <Text>Add Filters</Text>
                    </View>
                )}
            </TouchableOpacity>

            {!loading && scheduled_calls.length > 0 && (
                <View style={[tailwind`mt-2 w-full`, { maxHeight: height - 260 }]}>
                    <Text style={tailwind`text-blue-500 font-bold`}>
                        Total Records : {scheduled_calls.length}
                    </Text>

                    <FlatList
                        data={scheduled_calls}
                        renderItem={({ item }) => <ScheduledItem item={item} statusUpdatedSuccess={statusUpdatedSuccess} />}
                        keyExtractor={(item, i) => i}
                        style={{ marginBottom: isWeb ? 0 : 20 }}
                        ListFooterComponent={renderLoader}
                    />
                </View>
            )}

            {loading && !loadMoreLoader && (
                <View style={[tailwind` my-4 w-full h-full`, styles.loadercontainer]}>
                    <ActivityIndicator />
                </View>
            )}

            {!loading && !loadMoreLoader && scheduled_calls.length == 0 && (
                <View
                    style={[tailwind` my-4 w-full justify-center h-full items-center`]}
                >
                    <Text>Records Not Found.</Text>
                </View>
            )}
        </View>
    );
};

export default Scheduled;

const styles = StyleSheet.create({
    loadercontainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        verticalAlign: "middle",
        marginTop: 20,
        // marginBottom: "50%",
    },
    activityIndicator: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        verticalAlign: "middle",
    },
    inputContainer: {
        marginBottom: 10,
        marginTop: 0,
    },
    inputStyle: {
        // borderWidth: 1,
        borderBottomWidth: 1.4,
        borderColor: "rgba(0, 0, 0, 0.3)",
        paddingHorizontal: 0,
        paddingVertical: 0,
        borderRadius: 2,
    },
    icon: {
        position: "absolute",
        right: 10,
        textAlign: "center",
        margin: "auto",
        justifyContent: "center",
        height: "100%",
        alignItems: "center",
        flex: 1,
        // top: "20%"
    },
    icon2: {
        position: "absolute",
        // right: 10,
        textAlign: "center",
        margin: "auto",
        justifyContent: "center",
        height: "100%",
        alignItems: "center",
        flex: 1,
        // top: "20%"
    },
});
