import React, { useState } from "react";
import useClasses from "../../../../../context/classes";
import { ActivityIndicator, Image, TouchableOpacity, View } from "react-native";
import Text from "../../../../../components/Text";
import useUser from "../../../../../context/user";
import tailwind from "twrnc";
import { TEACHERS } from "../../../../../api/instance";
import Alert from "../../../../../utils/alert";

const SubscriptionExpiryCard = () => {
  const { expiredSubscriptions } = useClasses();
  const { user } = useUser();

  const [loading, setLoading] = useState(false);

  const handleRenew = async () => {
    setLoading(true);
    try {
      let params = {}
      await TEACHERS.post(`/users/${user.phone}/subscriptions/renew`, params);

      Alert.success("Thank you! Someone will contact you shortly!");
      setLoading(false);
    } catch (error) {
      Alert.alert("An error occured");
      setLoading(false);
      console.log("error in handleRenew");
    }
  };

  return (
    <>
      {expiredSubscriptions && (
        <View style={tailwind`mt-5 bg-[#FFF1E7] px-5 py-5 relative w-full`}>
          <Image
            style={tailwind`h-[115px] w-[110px] absolute bottom-0 right-0`}
            source={require("../../../../../../assets/img_background_expiry_card.png")}
          />
          <Image
            style={tailwind`h-[110px] w-[60px] absolute right-[40px] bottom-0`}
            source={require("../../../../../../assets/img_girl_folded_hands.png")}
          />
          <View style={tailwind`flex flex-col items-start max-w-[250px]`}>
            <Text style={tailwind`font-bold text-[16px]`}>
              Hey,{user.name}!
            </Text>
            <Text style={tailwind`mt-2`}>
              We are sorry, but your classes expired or are on hold since{" "}
              <Text style={tailwind`text-[#FF6B00] font-bold`}>
                {expiredSubscriptions.latestSubscriptionDate}
              </Text>
            </Text>

            <Text style={tailwind`mt-2 text-[10px]`}>
              Please click here to renew/resume your classes and someone from
              our team will contact you shortly.
            </Text>

            <TouchableOpacity
              onPress={handleRenew}
              style={tailwind`mt-5 px-5 py-2 rounded ${loading ? "bg-[#c6c6c6]" : "bg-[#FF902B]"
                }`}
            >
              {loading ? (
                <ActivityIndicator color={"#fff"} />
              ) : (
                <Text style={tailwind`text-white font-bold`}>
                  I Want to Renew
                </Text>
              )}
            </TouchableOpacity>
          </View>

          {/* <View style={tailwind`re right-0`}> */}

          {/* </View> */}
        </View>
      )}
    </>
  );
};

export default SubscriptionExpiryCard;
