import { StatusBar } from "expo-status-bar";
import Multibhashi from "./src";
import { registerRootComponent } from "expo";
import { AppContextProvider } from "./src/context/app";
import { Provider } from "react-native-paper";
import React, { useEffect } from 'react';
export default function App() {
  return (

    <>
      <StatusBar style="auto" />
      <AppContextProvider token>
        <Provider>
          <Multibhashi />
        </Provider>
      </AppContextProvider>
    </>
  );
}

registerRootComponent(App);
