// notificationService.js
import { getToken } from 'firebase/messaging';
import { messaging } from './src/lib/firebase';

// Function to register service worker
export const registerServiceWorker = async () => {
    if ('serviceWorker' in navigator) {
        try {
            const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
            // console.log('Service Worker registered:', registration);
            return registration;
        } catch (error) {
            console.error('Service Worker registration failed:', error);
        }
    } else {
        console.error('Service Workers are not supported in this browser.');
    }
};

// Function to request notification permissions
export const requestNotificationPermission = async () => {
    try {
        // Register the service worker
        const swRegistration = await registerServiceWorker();

        // Request notification permissions
        const permission = await Notification.requestPermission();
        if (permission === 'granted' && swRegistration) {
            // Get FCM token
            const token = await getToken(messaging, {
                vapidKey: 'BG7_6DjXZCGeSeGN7d0lBQLvM5BzBxq6HwE9oFK0OSSagUMHWkdTgnfvToPgcUpbKlRtvqr7hWI0-58eOltfvBc',
                serviceWorkerRegistration: swRegistration, // Pass the registered service worker
            });

            return token;
        } else {
            console.log('Permission not granted or Service Worker not registered.');
        }
    } catch (error) {
        console.error('Error getting notification permission:', error);
    }
};
